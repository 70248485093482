import React, { useContext, useMemo } from 'react';
// import styles from './SeatSlot.module.css';
import globalContext from '@/context/global/globalContext';
import { formatMoney } from '@/utils/Money';
import { getCardResource } from '@/utils/CardRes';

const SeatSlot = ({ pos, className, connId, seat, betLeft, betRight, betPos }) => {
  const { cardStyle } = useContext(globalContext);

  const actionView = useMemo(() => {
    const seatLastAction = seat.seatLastAction;

    return (
      <div className="player-action-pos" style={{ maxWidth: 145 }}>
        {seatLastAction ? (
          <div className="lastActionTexts magicTimeAction puffIn action-animation">
            {seatLastAction}
          </div>
        ) : null}
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seat, seat.refreshLastAction]);

  const cardsView = useMemo(() => {
    let path0 = null;
    let path1 = null;

    if (seat.playerId === connId || seat.seatShowCards) {
      // show cards
      if (seat.seatCard0) {
        path0 = getCardResource(seat.seatCard0, cardStyle);
      }
      if (seat.seatCard1) {
        path1 = getCardResource(seat.seatCard1, cardStyle);
      }
    } else {
      // hide cards
      if (seat.seatCard0) {
        path0 = getCardResource('', cardStyle);
      }
      if (seat.seatCard1) {
        path1 = getCardResource('', cardStyle);
      }
    }

    return (
      <div className="" style={{ boxSizing: 'border-box', display: 'flex', flexDirection: 'row' }}>
        <div className="" style={{ marginLeft: 5 }}>
          <div
            className={`cardOne magictime puffIn ${seat.seatWinningGlowCard0 ? 'card-glow' : ''}`}
            style={{
              backgroundImage: seat.seatCard0 ? `url(${path0})` : seat.seatIsFold ? 'url()' : '',
            }}
          ></div>
        </div>
        <div className="" style={{}}>
          <div
            className={`cardTwo magictime puffIn ${seat.seatWinningGlowCard1 ? 'card-glow' : ''}`}
            style={{
              backgroundImage: seat.seatCard1 ? `url(${path1})` : seat.seatIsFold ? 'url()' : '',
            }}
          ></div>
        </div>
        <div className=""></div>
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    cardStyle,
    seat.seatCard0,
    seat.seatCard1,
    seat.seatWinningGlowCard0,
    seat.seatWinningGlowCard1,
  ]);

  return (
    <div>
      <div
        id={'S-' + seat.id}
        style={{ position: 'relative' }}
        className={`SeatFrame ${className}`}
      >
        {actionView}
        <div className="" style={{}}>
          {cardsView}
        </div>
        <div className="" style={{ marginTop: '-30px', maxWidth: 170 }}>
          <div id="CardView" className={`card ${seat.cardAnimation ? 'card-animation' : ''}`}>
            <div id="Name" className="seatTexts">
              {seat.seatName}
            </div>
            <div id="Money" className="seatTexts">
              {formatMoney(seat.seatMoney)}
            </div>
            <div className="progress" style={{ height: 5 }}>
              <div
                className="progress-bar"
                role="progressbar"
                id="TimeBar"
                aria-valuemin="0"
                aria-valuemax="100"
                style={
                  seat.seatTimeBar > 0
                    ? {
                        width: '100%',
                        animation: `lineburn ${seat.seatTimeBar / 1000}s linear forwards`,
                      }
                    : {}
                }
              ></div>
            </div>
          </div>
        </div>
        {seat.seatBetFrame ? (
          <div
            id="BetFrame"
            className={`container ${seat.seatDoBet ? 'magictime puffIn' : ''} bet-pos ${
              betLeft ? 'bet-left' : ''
            } ${betRight ? 'bet-right' : ''}
            `}
            style={{
              animation: seat.seatCollectChips ? pos + 'ChipsToPot 0.5s alternate' : '',
            }}
          >
            <div id="TotalBet" style={styleBet(betPos)} className="betTexts bgBetText">
              <span>{seat.seatTotalBet}</span>
            </div>
          </div>
        ) : (
          ''
        )}
        {seat.seatDealerChip ? (
          <div id="DealerChip" style={styleDealer(betPos)} className="dealerChipView"></div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

const styleDealer = (betPos) => {
  if (betPos === 'top') {
    return {
      position: 'absolute',
      top: 153,
      left: 35,
    };
  }

  if (betPos === 'bottom') {
    return {
      position: 'absolute',
      top: -48,
      left: 35,
    };
  }

  if (betPos === 'right') {
    return {
      position: 'absolute',
      top: 27,
      left: -45,
    };
  }

  return {
    position: 'absolute',
    top: 27,
    left: 113,
  };
};

const styleBet = (betPos) => {
  if (betPos === 'top') {
    return {
      position: 'absolute',
      top: 125,
      left: 35,
      textAlign: 'center',
    };
  }

  if (betPos === 'bottom') {
    return {
      position: 'absolute',
      top: 0,
      left: 35,
      textAlign: 'center',
    };
  }

  if (betPos === 'right') {
    return {
      position: 'absolute',
      top: 70,
      left: -45,
      textAlign: 'right',
    };
  }

  return {
    position: 'absolute',
    top: 70,
    left: 113,
    textAlign: 'left',
  };
};

export default SeatSlot;
