import React, { useContext, useEffect } from 'react';
import socketContext from '@/context/websocket/socketContext';
import { toast } from 'react-toastify';
import authContext from '@/context/auth/authContext';
import roomContext from '@/context/room/roomContext';
import { useNavigate } from 'react-router-dom';
// import { useInitDataRaw } from '@telegram-apps/sdk-react';
import { retrieveLaunchParams } from '@telegram-apps/sdk';
// import Footer from '@/components/navigation/Footer';

const playerNickname = 'Anon' + Math.floor(Math.random() * 1000);

const HomePage = () => {
  const socketCtx = useContext(socketContext);
  const authCtx = useContext(authContext);
  const roomCtx = useContext(roomContext);
  const { socket, connId, socketKey } = socketCtx;
  const { setIsLoggedIn } = authCtx;
  const { setRoomId } = roomCtx;

  const navigate = useNavigate();

  // const data = useInitDataRaw();

  function userLogin(username, password) {
    // SHA3-512
    if (socket) {
      socket.send(
        JSON.stringify({
          connectionId: connId,
          socketKey: socketKey,
          key: 'userLogin',
          name: username,
          password: '',
        })
      );
    }
  }

  const regAuthHandler = (socket) => {
    socket.handle('loginResult', (jsonData) => loginResult(jsonData.data));
  };

  const getRooms = (socket, roomSortParam) => {
    if (socket) {
      // if (roomId === -1) {
      const data = JSON.stringify({
        connectionId: connId,
        socketKey: socketKey,
        key: 'getRooms',
        playerName: playerNickname,
        roomId: -1,
        roomSortParam: roomSortParam,
      });
      socket.send(data);
      // } else {
      // toast.warn('reload when already in a room');
      // TODO:
      // window.location.reload();
      // }
    }
  };

  function selectRoom(room_id) {
    if (socket) {
      const data = JSON.stringify({
        connectionId: connId,
        socketKey: socketKey,
        key: 'selectRoom',
        roomId: room_id,
      });
      socket.send(data);
    }
  }

  const regGameHandler = (socket) => {
    // Example: {"key":"getRooms","data":[{"roomId":0,"roomName":"Room 0","playerCount":0,"maxSeats":6},{"roomId":1,"roomName":"Room 1","playerCount":0,"maxSeats":6},{"roomId":2,"roomName":"Room 2","playerCount":0,"maxSeats":6}]}
    socket.handle('getRooms', (jsonData) => parseRooms(jsonData.data));

    // socket.handle('getSpectateRooms', (jsonData) => parseRooms(jsonData.data));
  };

  const parseRooms = (rData) => {
    console.log(rData);
    let randomRoomIndex = Math.floor(Math.random() * rData.length);
    console.log(rData[randomRoomIndex]);
    const room_id = Number(rData[randomRoomIndex].roomId);
    console.log(room_id);
    setRoomId(room_id);
    selectRoom(room_id);
    navigate('/game');
  };

  function loginResult(lData) {
    if (lData.result) {
      toast.success('You are now logged in for this instance.');
      // Need to get all room's again or exit all running game.
      // TODO: leave all table to login
      // reconnect();

      setIsLoggedIn({
        username: lData.username,
        password: lData.password,
      });
      getRooms(socket, 'all');
      // closeModal();
    } else {
      toast.error('Login failed! Check your username and password.');
    }
  }

  useEffect(() => {
    if (socket) {
      regAuthHandler(socket);
      regGameHandler(socket);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  return (
    <div className="background_main">
      <p
        style={{
          color: 'white',
          fontSize: '3rem',
          fontWeight: 'bold',
        }}
      >
        OPEN POKER
      </p>
      <button
        className="btn-playnow"
        onClick={() => {
          userLogin(retrieveLaunchParams().initDataRaw, '');
          // userLogin(
          //   'query_id=AAGv1l0ZAAAAAK_WXRkMWZHU&user=%7B%22id%22%3A425580207%2C%22first_name%22%3A%22Tran%22%2C%22last_name%22%3A%22Hung%22%2C%22username%22%3A%22tdh4vn%22%2C%22language_code%22%3A%22en%22%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1721652800&hash=cacb9983704941675eb2bfd1a062bbd70ff39e919d1da7f4368a62bb2a146953',
          //   ''
          // );
        }}
      >
        PLAY NOW
      </button>
    </div>
  );
};

export default HomePage;
