import React from 'react';
// import RoomStatus from '@/components/game/RoomStatus';
import RoomTable from '@/components/game/RoomTable';
import BoardCards from '@/components/game/BoardCards';
import TurnControl from '@/components/game/TurnControl';

const Room = () => {
  return (
    <div style={{ position: 'relative' }}>
      <div style={{ position: 'absolute', width: '100%' }}>
        <RoomTable></RoomTable>
      </div>
      <div
        style={{
          position: 'absolute',
          top: '40vh',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <BoardCards />
      </div>
      <div
        style={{
          width: '100%',
          position: 'absolute',
          height: '100%',
          top: '92vh',
        }}
      >
        <TurnControl />
      </div>
    </div>
  );
};

export default Room;
