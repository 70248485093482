import React from 'react';
import Room from '@/components/game/Room';
// import Footer from '@/components/navigation/Footer';

const GamePage = () => {
  return (
    <div>
      <Room />
      {/* <Footer /> */}
    </div>
  );
};

export default GamePage;
