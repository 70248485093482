import React, { useContext } from 'react';
import SeatSlot from './SeatSlot';
import roomContext from '@/context/room/roomContext';

const RoomTable = ({ children }) => {
  const { seats } = useContext(roomContext);

  const current = seats.data;
  return (
    <div className="poker-table">
      <div style={{ height: '180px', display: 'flex', justifyContent: 'center' }}>
        {current[0] && current[0].seatFrame ? (
          <SeatSlot
            pos="s1"
            style={{ margin: 'auto', maxWidth: 170 }}
            className=""
            seat={current[0]}
            betRight
            betPos={'top'}
          />
        ) : (
          ''
        )}
      </div>
      <div
        className="row"
        style={{
          height: '180px',
          display: 'flex',
          direction: 'row',
          justifyContent: 'space-between',
        }}
      >
        <div className="col">
          {current[1] && current[1].seatFrame ? (
            <SeatSlot pos="s2" seat={current[1]} betRight className="float-left" betPos={'left'} />
          ) : (
            ''
          )}
        </div>
        <div className="col">
          {current[2] && current[2].seatFrame ? (
            <SeatSlot pos="s3" seat={current[2]} betLeft className="float-right" betPos={'right'} />
          ) : (
            ''
          )}
        </div>
      </div>
      <div
        className="row"
        style={{
          height: '180px',
          display: 'flex',
          direction: 'row',
          justifyContent: 'space-between',
        }}
      >
        <div className="col">
          {current[3] && current[3].seatFrame ? (
            <SeatSlot pos="s4" seat={current[3]} betRight className="float-left" betPos={'left'} />
          ) : (
            ''
          )}
        </div>
        <div className="col">
          {current[4] && current[4].seatFrame ? (
            <SeatSlot pos="s5" seat={current[4]} betLeft className="float-right" betPos={'right'} />
          ) : (
            ''
          )}
        </div>
      </div>
      <div
        style={{
          height: '140px',
          display: 'flex',
          justifyContent: 'center',
          position: 'absolute',
          bottom: 0,
          width: '100%',
          marginBottom: 30,
        }}
      >
        {current[5] && current[5].seatFrame ? (
          <SeatSlot
            pos="s6"
            style={{ margin: 'auto' }}
            className=""
            seat={current[5]}
            betRight
            betPos={'bottom'}
          />
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default RoomTable;
